import React from 'react'
import { graphql } from 'gatsby'
import {Container} from "react-bootstrap"
import Layout from '../components/Layout'
import Link from "../components/Link"
import HeroHeadline from "../components/FlexibleContent/HeroHeadline"

const NotFoundPage = ({ data }) => {
	const siteTitle = data.site.siteMetadata.title

	return (
		<Layout title={siteTitle}>
			<Container>
				<br/>
				<HeroHeadline
					headline="404: Not Found"
					includeContainer={true}
					headingSize="h1"
					includePadding={true}
					removeTopPadding={true}
				>
				</HeroHeadline>
				<div className="editor-section">
					<p>Our apologies, it appears what you’re looking for isn’t at this link. Please try our search bar on our <Link to="/">home page</Link>.</p>
				</div>
				<br/>
			</Container>
		</Layout>
	)
}

export default NotFoundPage

export const query = graphql`
	{
		site {
			siteMetadata {
				title
			}
		}
	}
`
